<template>
  <!-- 联系我们 -->
  <div id="contact">
    <div class="contact">
      <Top />
    </div>
    <div class="contacttext">
      <div class="jianjie-title">
        <div class="jianjie-title-c">广东凯洋国际物流供应链有限公司</div>
                <div class="jianjie-title-e" style="margin-top:10px;font-size:17px">Guangdong Kayon International Logistics Supply Chain Co.,Ltd</div>

      </div>
      <p>
        开启现代服务业崭新模式，以精品服务赢取后勤社会化无限商机以服务创新以“专注、创新、务实、以客户为中心”的精神，解决了一项又一项运输难题，是国内一家正真有实力的货运代理。
      </p>
    </div>
    <!-- 联系方式 -->
    <div class="lianxi">
      <div class="lianxi-center">
        <div>
          <img src="https://gdkayon.oss-cn-shenzhen.aliyuncs.com/image/dw.png" alt="" />
          <div class="lianxifs">公司地址</div>
          <p>广东省东莞市高埗镇北王路 高埗段86号3号楼1039室</p>
        </div>
        <div>
          <img src="https://gdkayon.oss-cn-shenzhen.aliyuncs.com/image/dianhua.png" alt="" />
          <div class="lianxifs">联系电话</div>
          <p style="text-align: center; width: 100%">
            电话： 0769-81731186 <br />
            传真： 0769-81731196
          </p>
        </div>
        <div>
          <img src="https://gdkayon.oss-cn-shenzhen.aliyuncs.com/image/email.png" alt="" />
          <div class="lianxifs">联系邮箱</div>
          <p style="text-align: center; width: 100%">akon@gdkayon.com</p>
        </div>
      </div>
    </div>
    <!-- 地图 -->
    <div class="map">
      <div id="container"></div>
    </div>
    <Footer />
  </div>
</template>

<script>
import MapLoader from "@/assets/js/map.js";
import Footer from "../components/footer.vue";
import Top from "../components/topnav.vue";
export default {
  data() {
    return {
      map: null,
    };
  },
  components: {
    Footer,
    Top,
  },
  mounted() {
       let that = this;
    MapLoader().then(
      (AMap) => {
        that.map = new AMap.Map("container", {
          center: [113.762792,23.114867],
          zoom: 111,
          viewMode: "3D",
          pitch: 30,
        });
        let marker = new AMap.Marker({
          position: that.map.getCenter(),
          icon: new AMap.Icon({
            image:
              "https://cdn.yitailife.com/guanwang/static/dw.png", //定位点显示的图标
            size: new AMap.Size(60, 60), // 图标大小
            imageSize: new AMap.Size(26, 26),
          }),
          offset: new AMap.Pixel(-30, -60), //图标点的位置
        });
        marker.setMap(that.map);
      },
      (e) => {
        console.log("地图加载失败", e);
      }
    );
  },
};
</script>

<style lang="scss" scoped>
#contact {
  width: 100%;
}
.contact {
  width: 100%;
  height: 100px;
  background-color: #00263d;
}
// 联系我们
.contacttext {
  width: 1200px;
  margin: 100px auto;
  text-align: center;
  > p {
    display: inline-block;
    text-align: left;
    text-indent: 30px;
    margin-top: 28px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #444444;
    line-height: 28px;
  }
}

// 联系方式{
.lianxi {
  width: 100%;
  height: 464px;
  background-color: #e6e6e6;
  margin-top: 98px;
  padding-top: 21px;
  &-center {
    margin: 0px auto;
    width: 1200px;
    display: flex;
    justify-content: space-between;
    > div {
      width: 350px;
      height: 300px;
      margin-top: 81px;
      position: relative;
      background-color: white;
      padding: 0px 50px;
      box-sizing: border-box;
      > img {
        position: absolute;
        top: -81px;
        left: 50%;
        transform: translateX(-51%);
        width: 170px;
        height: 170px;
      }
      > .lianxifs {
        text-align: center;
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #1a1a1a;
        margin-top: 111px;
      }
      > p {
        display: inline-block;
        margin-top: 28px;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #1a1a1a;
        line-height: 30px;
      }
    }
  }
}
// 地图
.map {
  // margin-top: 50px;
  height: 800px;
  // margin-bottom: 92px;
}
#container{
    height: 800px;
    width: 100%;
}
</style>